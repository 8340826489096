<template>
  <section class="tj-call-to-action" :style="{ backgroundImage: 'url(' + backgroundImage + ')', backgroundColor: backgroundColor }">
    <div class="tj-call-to-action__text-container">
      <!--@slot Use this slot to replace title-->
      <slot name="title" v-bind="{ title }">
        <h2 v-if="title" class="tj-call-to-action__title">
          {{ title }}
        </h2>
      </slot>
      <!--@slot Use this slot to replace description-->
      <slot name="description" v-bind="{ description }">
        <p v-if="description" class="tj-call-to-action__description">
          {{ description }}
        </p>
      </slot>
    </div>
    <!--@slot Use this slot to replace bottom button-->
    <slot name="button" v-bind="{ buttonText }">
      <TjButton v-if="buttonText" :to="to" :href="href" class="tj-call-to-action__button">
        {{ buttonText }}
      </TjButton>
    </slot>
  </section>
</template>

<script>
import TjButton from "../components/atoms/TjButton.vue";

export default {
  components: {
    TjButton: TjButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    to: {
      type: [String, Object],
      default: "",
    },
    href: {
      type: [String, Object],
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    backgroundImage: {
      type: [String, Object],
      default: "",
    },
  },
};
</script>

<style scoped>
.tj-call-to-action {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  min-height: 12.625rem;
  padding: 2.5rem 5rem;
  background-color: transparent;
  background-position: center;
}
.tj-call-to-action__text-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 75%;
}
.tj-call-to-action__title {
  margin: 0 0 0.5rem 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 1.4;
  text-transform: none;
}
.tj-call-to-action__description {
  margin: 0 0 1.5rem 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
}
@media (max-width: 1279px) {
  .tj-call-to-action {
    flex-direction: column;
    padding: 2rem;
  }
  .tj-call-to-action__text-container {
    justify-content: flex-start;
    flex-direction: column;
  }
  .tj-call-to-action__title {
    margin: 0 0 1.5rem 0;
    font-size: 1.5rem;
  }
}
</style>