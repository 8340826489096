<template>
  <div>
    <!-- <iframe
      style="margin-top: 2rem"
      src="https://ads-partners.coupang.com/widgets.html?id=482603&template=carousel&trackingCode=AF9555338&subId=&width=1240&height=100"
      width="100%"
      height="100"
      frameborder="0"
      scrolling="no"
      referrerpolicy="unsafe-url"
    ></iframe> -->

    <!-- New Products -->
    <div class="brand-new">
      <p class="brand-new__paragraph">신상품</p>
      <TjButton
        class="tj-button__text"
        href="https://smartstore.naver.com/taejinshop"
        >전체상품 보러가기</TjButton
      >
    </div>
    <TjCarousel :items="newProducts">
      <TjProductCard
        slot-scope="slotProps"
        :item="slotProps.item"
      ></TjProductCard>
    </TjCarousel>

    <!-- Call to Action -->
    <TjCallToAction
      class="tj-call-to-action"
      title="유튜브 구독하기"
      description="새로운 제품 및 이벤트 소식을 받아보세요. 선물과 특별 혜택을 받아보세요!"
      buttonText="구독하기"
      href="https://www.youtube.com/channel/UCl85zz4lYwkH9xfO2bWBl0w?sub_confirmation=1"
      :backgroundImage="require('../assets/newsletter.jpg')"
    ></TjCallToAction>

    <!-- Planned Products -->
    <div class="brand-new">
      <p class="brand-new__paragraph">민물낚시용품</p>
      <TjButton
        class="tj-button__text"
        href="https://smartstore.naver.com/taejinshop"
        >전체상품 보러가기</TjButton
      >
    </div>
    <TjCarousel :items="plannedProducts1">
      <TjProductCard
        slot-scope="slotProps"
        :item="slotProps.item"
      ></TjProductCard>
    </TjCarousel>

    <!-- Planned Products -->
    <div class="brand-new">
      <p class="brand-new__paragraph">민물낚시 보조용품</p>
      <TjButton
        class="tj-button__text"
        href="https://smartstore.naver.com/taejinshop"
        >전체상품 보러가기</TjButton
      >
    </div>
    <TjCarousel :items="plannedProducts2">
      <TjProductCard
        slot-scope="slotProps"
        :item="slotProps.item"
      ></TjProductCard>
    </TjCarousel>

    <!-- <iframe
      style="margin-top: 1rem"
      src="https://ads-partners.coupang.com/widgets.html?id=482603&template=carousel&trackingCode=AF9555338&subId=&width=1240&height=100"
      width="100%"
      height="100"
      frameborder="0"
      scrolling="no"
      referrerpolicy="unsafe-url"
    ></iframe> -->

    <!-- Planned Products -->
    <div class="brand-new">
      <p class="brand-new__paragraph">바다낚시용품</p>
      <TjButton
        class="tj-button__text"
        href="https://smartstore.naver.com/taejinshop"
        >전체상품 보러가기</TjButton
      >
    </div>
    <TjCarousel :items="plannedProducts3">
      <TjProductCard
        slot-scope="slotProps"
        :item="slotProps.item"
      ></TjProductCard>
    </TjCarousel>

    <!-- All Products -->
    <div class="brand-new">
      <p class="brand-new__paragraph">상품전체</p>
      <TjButton
        class="tj-button__text"
        href="https://smartstore.naver.com/taejinshop"
        >전체상품 보러가기</TjButton
      >
    </div>
    <TjCarousel :items="allProducts">
      <TjProductCard
        slot-scope="slotProps"
        :item="slotProps.item"
      ></TjProductCard>
    </TjCarousel>
  </div>
</template>

<script>
import TjCallToAction from "../components/TjCallToAction.vue";
import TjCarousel from "../components/TjCarousel.vue";
import TjProductCard from "../components/TjProductCard.vue";
import TjButton from "../components/atoms/TjButton.vue";

export default {
  components: {
    TjCallToAction: TjCallToAction,
    TjCarousel: TjCarousel,
    TjProductCard: TjProductCard,
    TjButton: TjButton,
  },
  props: {
    modelName: {
      type: String,
      default: "",
    },
  },
  methods: {
    getYoutubeSrc(id) {
      if (!id) {
        return "";
      }
      return "https://youtu.be/" + id;
    },
  },
  created() {
    if (this.modelName) {
      this.$router.push({
        name: "Detail",
        query: {
          modelName: this.modelName,
        },
      });
    }
  },
  data() {
    return {
      newProducts: [
        {
          badgeLabel: "신상품",
          image: require("../assets/products/TJ-601.png"),
          title: "TJ-601 태진낚시 태진보트",
          regularPrice: "￦ 3,300,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          to: "/detail?modelName=TJ_601",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771292157",
          image: require("../assets/products/TJ-518.png"),
          title: "TJ-518 자립발판",
          regularPrice: "￦ 300,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771292157",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771250629",
          image: require("../assets/products/TJ-517.png"),
          title: "TJ-517 미끼통 대포 거치대",
          regularPrice: "￦ 140,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771250629",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771214830",
          image: require("../assets/products/TJ-516.png"),
          title: "TJ-516 갈고리",
          regularPrice: "￦ 50,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771214830",
        },
        {
          badgeLabel: "신상품 / 11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/6621917550",
          image: require("../assets/products/TJ-303-07.jpg"),
          title: "TJ-303-07N 태진낚시 민물텐트좌대 2세대 (2000 x 2000mm)",
          regularPrice: "￦ 2,600,000",
          specialPrice: "￦ 2,300,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6621917550",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6234843461",
          image: require("../assets/products/TJ-320.png"),
          title: "TJ-320 베란다 계단걸이 (태진좌대전용) (높이 200mm)",
          regularPrice: "￦ 65,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234843461",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6234817778",
          image: require("../assets/products/TJ-319.png"),
          title:
            "TJ-319 받침틀 베란다 13단 (태진좌대전용) (350 X 1000mm) 받침대 별도 구매",
          regularPrice: "￦ 250,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234817778",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6234794466",
          image: require("../assets/products/TJ-318.png"),
          title: "TJ-318 베란다 (태진좌대전용) (350 X 1000mm)",
          regularPrice: "￦ 180,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234794466",
        },
      ],
      plannedProducts1: [
        {
          badgeLabel: "신상품",
          image: require("../assets/products/TJ-601.png"),
          title: "TJ-601 태진낚시 태진보트",
          regularPrice: "￦ 3,300,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          to: "/detail?modelName=TJ_601",
        },
        {
          badgeLabel: "가로형 / 11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/6324954501",
          image: require("../assets/products/TJ-303-05.jpg"),
          title: "TJ-303-09 태진낚시 민물텐트좌대 2세대 (2000 x 1500mm) 가로형",
          regularPrice: "￦ 2,040,000",
          specialPrice: "￦ 1,800,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6324954501",
        },
        {
          badgeLabel: "12% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/5840269326",
          image: require("../assets/products/TJ-303-06.jpg"),
          title: "TJ-303-08 태진낚시 민물텐트좌대 2세대 (2000 x 1800mm)",
          regularPrice: "￦ 2,400,000",
          specialPrice: "￦ 2,100,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5840269326",
        },
        {
          badgeLabel: "11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/5489281837",
          image: require("../assets/products/TJ-303-07.jpg"),
          title:
            "TJ-303-07 태진낚시 민물텐트좌대 2세대 (2000 x 2000mm, 날개포함)",
          regularPrice: "￦ 2,600,000",
          specialPrice: "￦ 2,300,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489281837",
        },
        {
          badgeLabel: "신상품 / 날개 미포함 / 11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/6621917550",
          image: require("../assets/products/TJ-303-07.jpg"),
          title: "TJ-303-07N 태진낚시 민물텐트좌대 2세대 (2000 x 2000mm)",
          regularPrice: "￦ 2,600,000",
          specialPrice: "￦ 2,300,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6621917550",
        },
        {
          badgeLabel: "11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/5298449263",
          image: require("../assets/products/TJ-303-06.jpg"),
          title: "TJ-303-06 태진낚시 민물텐트좌대 2세대 (2000 x 1700mm)",
          regularPrice: "￦ 2,200,000",
          specialPrice: "￦ 1,950,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5298449263",
        },
        {
          badgeLabel: "11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/752588468",
          image: require("../assets/products/TJ-303-05.jpg"),
          title: "TJ-303-05 태진낚시 민물텐트좌대 2세대 (1500 x 2000mm)",
          regularPrice: "￦ 2,040,000",
          specialPrice: "￦ 1,800,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752588468",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752581615",
          image: require("../assets/products/TJ-303-04.png"),
          title: "TJ-303-04 태진낚시 민물좌대 2세대 (1000 x 1500mm)",
          regularPrice: "￦ 1,200,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752581615",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752574977",
          image: require("../assets/products/TJ-303-02.png"),
          title: "TJ-303-02 태진낚시 민물발판좌대 2세대 (750 x 1000mm)",
          regularPrice: "￦ 750,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752574977",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752535676",
          image: require("../assets/products/TJ-302.png"),
          title: "태진낚시 민물발판좌대 1세대",
          regularPrice: "￦ 360,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752535676",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752527626",
          image: require("../assets/products/TJ-301.png"),
          title: "태진낚시 민물좌대 1세대",
          regularPrice: "￦ 390,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752527626",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489990354",
          image: require("../assets/products/TJ-211.png"),
          title: "태진낚시 이탈방지받침틀 2세대",
          regularPrice: "￦ 700,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489990354",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/2208196987",
          image: require("../assets/products/TJ-207.png"),
          title: "태진낚시 이탈방지받침틀 1세대",
          regularPrice: "￦ 600,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/2208196987",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5415053551",
          image: require("../assets/products/TJ-208.png"),
          title: "태진낚시 민물 릴 원투낚시 받침틀 (5단)",
          regularPrice: "￦ 650,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5415053551",
        },
      ],
      plannedProducts2: [
        {
          badgeLabel: "신상품",
          image: require("../assets/products/TJ-601.png"),
          title: "TJ-601 태진낚시 태진보트",
          regularPrice: "￦ 3,300,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          to: "/detail?modelName=TJ_601",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771292157",
          image: require("../assets/products/TJ-518.png"),
          title: "TJ-518 자립발판",
          regularPrice: "￦ 300,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771292157",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771250629",
          image: require("../assets/products/TJ-517.png"),
          title: "TJ-517 미끼통 대포 거치대",
          regularPrice: "￦ 140,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771250629",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771214830",
          image: require("../assets/products/TJ-516.png"),
          title: "TJ-516 갈고리",
          regularPrice: "￦ 50,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771214830",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234843461",
          image: require("../assets/products/TJ-320.png"),
          title: "TJ-320 베란다 계단걸이 (태진좌대전용) (높이 200mm)",
          regularPrice: "￦ 65,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234843461",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234817778",
          image: require("../assets/products/TJ-319.png"),
          title:
            "TJ-319 받침틀 베란다 13단 (태진좌대전용) (350 X 1000mm) 받침대 별도 구매",
          regularPrice: "￦ 250,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234817778",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234794466",
          image: require("../assets/products/TJ-318.png"),
          title: "TJ-318 베란다 (태진좌대전용) (350 X 1000mm)",
          regularPrice: "￦ 180,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234794466",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234702596",
          image: require("../assets/products/TJ-515.png"),
          title: "TJ-515 태진기계 태진낚시 낚시대 거치대 대포 (태진좌대전용)",
          regularPrice: "￦ 40,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234702596",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234602499",
          image: require("../assets/products/TJ-212.png"),
          title: "TJ-212 태진기계 태진낚시 붕어 뜰채 거치대 (태진좌대전용)",
          regularPrice: "￦ 40,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234602499",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234741814",
          image: require("../assets/products/TJ-317.png"),
          title:
            "TJ-317 태진기계 태진낚시 접지 클램프. 파라솔 클램프. 폴대 클램프 공용",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234741814",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234531956",
          image: require("../assets/products/TJ-316.png"),
          title:
            "TJ-316 태진기계 태진낚시 무동력 가스 온수보일러 포트 거치대 캠핑용 삼각대 다리 무동력 보일러",
          regularPrice: "￦ 21,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234531956",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234520046",
          image: require("../assets/products/TJ-315.png"),
          title:
            "TJ-315 태진기계 태진낚시 무동력 가스 온수보일러 포트 거치대 캠핑용 삼각대 무동력 보일러",
          regularPrice: "￦ 36,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234520046",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6027149256",
          image: require("../assets/products/TJ-314.png"),
          title: "TJ-314 태진기계 태진낚시 무동력 가스 온수보일러 본체",
          regularPrice: "￦ 160,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6027149256",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6027143135",
          image: require("../assets/products/TJ-313.png"),
          title:
            "TJ-313 태진기계 태진낚시 무동력 가스 온수보일러 포트 바람막이",
          regularPrice: "￦ 25,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6027143135",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6027135231",
          image: require("../assets/products/TJ-312.png"),
          title: "TJ-312 태진기계 태진낚시 무동력 가스 온수보일러 포트 거치대",
          regularPrice: "￦ 15,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6027135231",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6027103368",
          image: require("../assets/products/TJ-311.png"),
          title:
            "TJ-311 태진기계 태진낚시 무동력 가스 온수보일러 풀세트 플라스틱 케이스 포함",
          regularPrice: "￦ 230,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6027103368",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5642612322",
          image: require("../assets/products/TJ-310-1.png"),
          title:
            "태진낚시 파라솔타격팩 거치대 고정 / 갯바위망치 팩망치 해머 세트",
          regularPrice: "￦ 72,000",
          specialPrice: "￦ 58,900",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5642612322",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5642349748",
          image: require("../assets/products/TJ-310.png"),
          title: "태진낚시 파라솔타격팩 거치대 고정팩",
          regularPrice: "￦ 36,000",
          specialPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5642349748",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5613459465",
          image: require("../assets/products/SH100088.png"),
          title: "이지코리아 BR-311 리필전지 50개",
          regularPrice: "￦ 40,000",
          specialPrice: "￦ 39,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5613459465",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5613466212",
          image: require("../assets/products/SH100100.png"),
          title: "이지코리아 이지 광케미 리필램프(3mm)",
          regularPrice: "￦ 5,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5613466212",
        },
        {
          image: require("../assets/products/TJ-306.png"),
          title: "태진낚시 무동력 가스 온수보일러",
          regularPrice: "￦ 280,000",
          youtube: this.getYoutubeSrc("fZ5zoWfOuMk"),
          to: "/detail?modelName=TJ_306",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5529986222",
          image: require("../assets/products/TJ-309.png"),
          title: "태진낚시 무동력 가스 온수보일러 거치대",
          regularPrice: "￦ 30,000",
          youtube: this.getYoutubeSrc("fZ5zoWfOuMk"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5529986222",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5527292807",
          image: require("../assets/products/TJ-308.png"),
          title: "태진낚시 세컨드 받침틀",
          regularPrice: "￦ 40,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5527292807",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752597923",
          image: require("../assets/products/TJ-307-01.png"),
          title: "태진낚시 민물좌대 징검다리 (기본형)",
          regularPrice: "￦ 320,000",
          youtube: this.getYoutubeSrc("pu3Xbg9TGGE"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752597923",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5522800084",
          image: require("../assets/products/TJ-307-02.png"),
          title: "태진낚시 민물좌대 징검다리 (고급형)",
          regularPrice: "￦ 540,000",
          youtube: this.getYoutubeSrc("pu3Xbg9TGGE"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5522800084",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5490013013",
          image: require("../assets/products/TJ-305.png"),
          title: "태진낚시 민물텐트좌대 텐트걸이세트(4개 1세트)",
          regularPrice: "￦ 60,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5490013013",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5490005420",
          image: require("../assets/products/TJ-304.png"),
          title: "태진낚시 민물텐트좌대 엑스바 (2개 1세트)",
          regularPrice: "￦ 60,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5490005420",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489965974",
          image: require("../assets/products/TJ-210.png"),
          title: "태진낚시 붕어 뜰채 거치대 (태진받침틀전용)",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489965974",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489958151",
          image: require("../assets/products/TJ-209.png"),
          title: "태진낚시 붕어 뜰채 거치대 (일반용)",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489958151",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752609346",
          image: require("../assets/products/TJ-201.png"),
          title: "태진낚시 민물받침대 동작형 세트",
          regularPrice: "￦ 570,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752609346",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752604657",
          image: require("../assets/products/TJ-200.png"),
          title: "태진낚시 동작형 단절 받침대",
          regularPrice: "￦ 20,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752604657",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489648418",
          image: require("../assets/products/TJ-509.png"),
          title: "태진낚시 파라솔 각도기",
          regularPrice: "￦ 190,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489648418",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489861757",
          image: require("../assets/products/TJ-514.png"),
          title: "태진낚시 파라솔 클램프",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489861757",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489749144",
          image: require("../assets/products/TJ-510.png"),
          title: "태진낚시 다용도 의자",
          regularPrice: "￦ 90,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489749144",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489631811",
          image: require("../assets/products/TJ-508.png"),
          title: "태진낚시 민물좌대 계측자",
          regularPrice: "￦ 35,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489631811",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489617465",
          image: require("../assets/products/TJ-506.png"),
          title: "태진낚시 좌대다리 연장연결대",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489617465",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489592339",
          image: require("../assets/products/TJ-504.png"),
          title: "태진낚시 접지다리 (2개 1세트)",
          regularPrice: "￦ 50,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489592339",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489573315",
          image: require("../assets/products/TJ-503.png"),
          title: "태진낚시 대륙 받침틀용",
          regularPrice: "￦ 20,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489573315",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489561927",
          image: require("../assets/products/TJ-502.png"),
          title: "태진낚시 섶다리 받침틀용",
          regularPrice: "￦ 21,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489561927",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752539091",
          image: require("../assets/products/TJ-511.png"),
          title: "태진낚시 낚시대 거치대 대포",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752539091",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752542535",
          image: require("../assets/products/TJ-512.png"),
          title: "태진낚시 텐트 고정대 세트",
          regularPrice: "￦ 65,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752542535",
        },
      ],
      plannedProducts3: [
        {
          badgeLabel: "신상품",
          image: require("../assets/products/TJ-601.png"),
          title: "TJ-601 태진낚시 태진보트",
          regularPrice: "￦ 3,300,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          to: "/detail?modelName=TJ_601",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489540662",
          image: require("../assets/products/TJ-501.png"),
          title: "태진낚시 갯바위 망치",
          regularPrice: "￦ 36,000",
          specialPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489540662",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489502505",
          image: require("../assets/products/TJ-106.png"),
          title: "태진낚시 갯바위 받침대 올스텐 2세대",
          regularPrice: "￦ 105,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489502505",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302092326",
          image: require("../assets/products/TJ-102.png"),
          title: "태진낚시 갯바위 받침대 올스텐 1세대",
          regularPrice: "￦ 70,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302092326",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489384846",
          image: require("../assets/products/TJ-101.png"),
          title: "태진낚시 돌돔받침대 민장대용 2세대 가스쇼바",
          regularPrice: "￦ 490,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489384846",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489364801",
          image: require("../assets/products/TJ-100.png"),
          title: "태진낚시 돌돔받침대 릴낚싯대용 2세대",
          regularPrice: "￦ 434,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489364801",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302007837",
          image: require("../assets/products/TJ-103.png"),
          title: "태진낚시 돌돔받침대 민장대용 1세대 가스쇼바",
          regularPrice: "￦ 245,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302007837",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302037828",
          image: require("../assets/products/TJ-104.png"),
          title: "태진낚시 돌돔받침대 릴낚싯대용 1세대",
          regularPrice: "￦ 185,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302037828",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302071501",
          image: require("../assets/products/TJ-108.png"),
          title: "태진낚시 돌돔 쌍받침대",
          regularPrice: "￦ 326,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302071501",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302256105",
          image: require("../assets/products/TJ-109.png"),
          title: "태진낚시 해상콘도선상용 받침대",
          regularPrice: "￦ 35,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302256105",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/3374243553",
          image: require("../assets/products/TJ-105.png"),
          title: "태진낚시 갯바위 파라솔 거치대",
          regularPrice: "￦ 105,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/3374243553",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302118034",
          image: require("../assets/products/TJ-107.png"),
          title: "태진낚시 낚싯대 받침대 아이스박스용, 쿨백용",
          regularPrice: "￦ 70,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302118034",
        },
      ],
      allProducts: [
        {
          image: require("../assets/products/TJ-601.png"),
          title: "TJ-601 태진낚시 태진보트",
          regularPrice: "￦ 3,300,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          to: "/detail?modelName=TJ_601",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771292157",
          image: require("../assets/products/TJ-518.png"),
          title: "TJ-518 자립발판",
          regularPrice: "￦ 300,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771292157",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771250629",
          image: require("../assets/products/TJ-517.png"),
          title: "TJ-517 미끼통 대포 거치대",
          regularPrice: "￦ 140,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771250629",
        },
        {
          badgeLabel: "신상품",
          href: "https://smartstore.naver.com/taejinshop/products/6771214830",
          image: require("../assets/products/TJ-516.png"),
          title: "TJ-516 갈고리",
          regularPrice: "￦ 50,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6771214830",
        },
        {
          badgeLabel: "가로형 / 11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/6324954501",
          image: require("../assets/products/TJ-303-05.jpg"),
          title: "TJ-303-09 태진낚시 민물텐트좌대 2세대 (2000 x 1500mm) 가로형",
          regularPrice: "￦ 2,040,000",
          specialPrice: "￦ 1,800,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6324954501",
        },
        {
          badgeLabel: "12% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/5840269326",
          image: require("../assets/products/TJ-303-06.jpg"),
          title: "TJ-303-08 태진낚시 민물텐트좌대 2세대 (2000 x 1800mm)",
          regularPrice: "￦ 2,400,000",
          specialPrice: "￦ 2,100,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5840269326",
        },
        {
          badgeLabel: "11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/5489281837",
          image: require("../assets/products/TJ-303-07.jpg"),
          title:
            "TJ-303-07 태진낚시 민물텐트좌대 2세대 (2000 x 2000mm, 날개포함)",
          regularPrice: "￦ 2,600,000",
          specialPrice: "￦ 2,300,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489281837",
        },
        {
          badgeLabel: "신상품 / 날개 미포함 / 11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/6621917550",
          image: require("../assets/products/TJ-303-07.jpg"),
          title: "TJ-303-07N 태진낚시 민물텐트좌대 2세대 (2000 x 2000mm)",
          regularPrice: "￦ 2,600,000",
          specialPrice: "￦ 2,300,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6621917550",
        },
        {
          badgeLabel: "11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/5298449263",
          image: require("../assets/products/TJ-303-06.jpg"),
          title: "TJ-303-06 태진낚시 민물텐트좌대 2세대 (2000 x 1700mm)",
          regularPrice: "￦ 2,200,000",
          specialPrice: "￦ 1,950,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5298449263",
        },
        {
          badgeLabel: "11% 할인",
          href: "https://smartstore.naver.com/taejinshop/products/752588468",
          image: require("../assets/products/TJ-303-05.jpg"),
          title: "TJ-303-05 태진낚시 민물텐트좌대 2세대 (1500 x 2000mm)",
          regularPrice: "￦ 2,040,000",
          specialPrice: "￦ 1,800,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752588468",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752581615",
          image: require("../assets/products/TJ-303-04.png"),
          title: "TJ-303-04 태진낚시 민물좌대 2세대 (1000 x 1500mm)",
          regularPrice: "￦ 1,200,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752581615",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752574977",
          image: require("../assets/products/TJ-303-02.png"),
          title: "TJ-303-02 태진낚시 민물발판좌대 2세대 (750 x 1000mm)",
          regularPrice: "￦ 750,000",
          youtube: this.getYoutubeSrc("mJ1KpfjcbvI"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752574977",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234843461",
          image: require("../assets/products/TJ-320.png"),
          title: "TJ-320 베란다 계단걸이 (태진좌대전용) (높이 200mm)",
          regularPrice: "￦ 65,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234843461",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234817778",
          image: require("../assets/products/TJ-319.png"),
          title:
            "TJ-319 받침틀 베란다 13단 (태진좌대전용) (350 X 1000mm) 받침대 별도 구매",
          regularPrice: "￦ 250,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234817778",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234794466",
          image: require("../assets/products/TJ-318.png"),
          title: "TJ-318 베란다 (태진좌대전용) (350 X 1000mm)",
          regularPrice: "￦ 180,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234794466",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234702596",
          image: require("../assets/products/TJ-515.png"),
          title: "TJ-515 태진기계 태진낚시 낚시대 거치대 대포 (태진좌대전용)",
          regularPrice: "￦ 40,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234702596",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234602499",
          image: require("../assets/products/TJ-212.png"),
          title: "TJ-212 태진기계 태진낚시 붕어 뜰채 거치대 (태진좌대전용)",
          regularPrice: "￦ 40,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234602499",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234741814",
          image: require("../assets/products/TJ-317.png"),
          title:
            "TJ-317 태진기계 태진낚시 접지 클램프. 파라솔 클램프. 폴대 클램프 공용",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234741814",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234531956",
          image: require("../assets/products/TJ-316.png"),
          title:
            "TJ-316 태진기계 태진낚시 무동력 가스 온수보일러 포트 거치대 캠핑용 삼각대 다리 무동력 보일러",
          regularPrice: "￦ 21,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234531956",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6234520046",
          image: require("../assets/products/TJ-315.png"),
          title:
            "TJ-315 태진기계 태진낚시 무동력 가스 온수보일러 포트 거치대 캠핑용 삼각대 무동력 보일러",
          regularPrice: "￦ 36,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6234520046",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6027149256",
          image: require("../assets/products/TJ-314.png"),
          title: "TJ-314 태진기계 태진낚시 무동력 가스 온수보일러 본체",
          regularPrice: "￦ 160,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6027149256",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6027143135",
          image: require("../assets/products/TJ-313.png"),
          title:
            "TJ-313 태진기계 태진낚시 무동력 가스 온수보일러 포트 바람막이",
          regularPrice: "￦ 25,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6027143135",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6027135231",
          image: require("../assets/products/TJ-312.png"),
          title: "TJ-312 태진기계 태진낚시 무동력 가스 온수보일러 포트 거치대",
          regularPrice: "￦ 15,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6027135231",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/6027103368",
          image: require("../assets/products/TJ-311.png"),
          title:
            "TJ-311 태진기계 태진낚시 무동력 가스 온수보일러 풀세트 플라스틱 케이스 포함",
          regularPrice: "￦ 230,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/6027103368",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5642612322",
          image: require("../assets/products/TJ-310-1.png"),
          title:
            "태진낚시 파라솔타격팩 거치대 고정 / 갯바위망치 팩망치 해머 세트",
          regularPrice: "￦ 72,000",
          specialPrice: "￦ 58,900",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5642612322",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5642349748",
          image: require("../assets/products/TJ-310.png"),
          title: "태진낚시 파라솔타격팩 거치대 고정팩",
          regularPrice: "￦ 36,000",
          specialPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5642349748",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489540662",
          image: require("../assets/products/TJ-501.png"),
          title: "태진낚시 갯바위 망치",
          regularPrice: "￦ 36,000",
          specialPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489540662",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5613459465",
          image: require("../assets/products/SH100088.png"),
          title: "이지코리아 BR-311 리필전지 50개",
          regularPrice: "￦ 40,000",
          specialPrice: "￦ 39,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5613459465",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5613466212",
          image: require("../assets/products/SH100100.png"),
          title: "이지코리아 이지 광케미 리필램프(3mm)",
          regularPrice: "￦ 5,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5613466212",
        },
        {
          image: require("../assets/products/TJ-306.png"),
          title: "태진낚시 무동력 가스 온수보일러",
          regularPrice: "￦ 280,000",
          youtube: this.getYoutubeSrc("fZ5zoWfOuMk"),
          to: "/detail?modelName=TJ_306",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5529986222",
          image: require("../assets/products/TJ-309.png"),
          title: "태진낚시 무동력 가스 온수보일러 거치대",
          regularPrice: "￦ 30,000",
          youtube: this.getYoutubeSrc("fZ5zoWfOuMk"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5529986222",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5527292807",
          image: require("../assets/products/TJ-308.png"),
          title: "태진낚시 세컨드 받침틀",
          regularPrice: "￦ 40,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5527292807",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752597923",
          image: require("../assets/products/TJ-307-01.png"),
          title: "태진낚시 민물좌대 징검다리 (기본형)",
          regularPrice: "￦ 320,000",
          youtube: this.getYoutubeSrc("pu3Xbg9TGGE"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752597923",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5522800084",
          image: require("../assets/products/TJ-307-02.png"),
          title: "태진낚시 민물좌대 징검다리 (고급형)",
          regularPrice: "￦ 540,000",
          youtube: this.getYoutubeSrc("pu3Xbg9TGGE"),
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5522800084",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5490013013",
          image: require("../assets/products/TJ-305.png"),
          title: "태진낚시 민물텐트좌대 텐트걸이세트(4개 1세트)",
          regularPrice: "￦ 60,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5490013013",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5490005420",
          image: require("../assets/products/TJ-304.png"),
          title: "태진낚시 민물텐트좌대 엑스바 (2개 1세트)",
          regularPrice: "￦ 60,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5490005420",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489965974",
          image: require("../assets/products/TJ-210.png"),
          title: "태진낚시 붕어 뜰채 거치대 (태진받침틀전용)",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489965974",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489958151",
          image: require("../assets/products/TJ-209.png"),
          title: "태진낚시 붕어 뜰채 거치대 (일반용)",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489958151",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5415053551",
          image: require("../assets/products/TJ-208.png"),
          title: "태진낚시 민물 릴 원투낚시 받침틀 (5단)",
          regularPrice: "￦ 650,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5415053551",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489990354",
          image: require("../assets/products/TJ-211.png"),
          title: "태진낚시 이탈방지받침틀 2세대",
          regularPrice: "￦ 700,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489990354",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/2208196987",
          image: require("../assets/products/TJ-207.png"),
          title: "태진낚시 이탈방지받침틀 1세대",
          regularPrice: "￦ 600,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/2208196987",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752609346",
          image: require("../assets/products/TJ-201.png"),
          title: "태진낚시 민물받침대 동작형 세트",
          regularPrice: "￦ 570,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752609346",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752604657",
          image: require("../assets/products/TJ-200.png"),
          title: "태진낚시 동작형 단절 받침대",
          regularPrice: "￦ 20,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752604657",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/3374243553",
          image: require("../assets/products/TJ-105.png"),
          title: "태진낚시 갯바위 파라솔 거치대",
          regularPrice: "￦ 105,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/3374243553",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489648418",
          image: require("../assets/products/TJ-509.png"),
          title: "태진낚시 파라솔 각도기",
          regularPrice: "￦ 190,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489648418",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302118034",
          image: require("../assets/products/TJ-107.png"),
          title: "태진낚시 낚싯대 받침대 아이스박스용, 쿨백용",
          regularPrice: "￦ 70,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302118034",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489861757",
          image: require("../assets/products/TJ-514.png"),
          title: "태진낚시 파라솔 클램프",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489861757",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489749144",
          image: require("../assets/products/TJ-510.png"),
          title: "태진낚시 다용도 의자",
          regularPrice: "￦ 90,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489749144",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489631811",
          image: require("../assets/products/TJ-508.png"),
          title: "태진낚시 민물좌대 계측자",
          regularPrice: "￦ 35,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489631811",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489617465",
          image: require("../assets/products/TJ-506.png"),
          title: "태진낚시 좌대다리 연장연결대",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489617465",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489592339",
          image: require("../assets/products/TJ-504.png"),
          title: "태진낚시 접지다리 (2개 1세트)",
          regularPrice: "￦ 50,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489592339",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489573315",
          image: require("../assets/products/TJ-503.png"),
          title: "태진낚시 대륙 받침틀용",
          regularPrice: "￦ 20,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489573315",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489561927",
          image: require("../assets/products/TJ-502.png"),
          title: "태진낚시 섶다리 받침틀용",
          regularPrice: "￦ 21,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489561927",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489502505",
          image: require("../assets/products/TJ-106.png"),
          title: "태진낚시 갯바위 받침대 올스텐 2세대",
          regularPrice: "￦ 105,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489502505",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302092326",
          image: require("../assets/products/TJ-102.png"),
          title: "태진낚시 갯바위 받침대 올스텐 1세대",
          regularPrice: "￦ 70,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302092326",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489384846",
          image: require("../assets/products/TJ-101.png"),
          title: "태진낚시 돌돔받침대 민장대용 2세대 가스쇼바",
          regularPrice: "￦ 490,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489384846",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5489364801",
          image: require("../assets/products/TJ-100.png"),
          title: "태진낚시 돌돔받침대 릴낚싯대용 2세대",
          regularPrice: "￦ 434,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5489364801",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302007837",
          image: require("../assets/products/TJ-103.png"),
          title: "태진낚시 돌돔받침대 민장대용 1세대 가스쇼바",
          regularPrice: "￦ 245,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302007837",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302037828",
          image: require("../assets/products/TJ-104.png"),
          title: "태진낚시 돌돔받침대 릴낚싯대용 1세대",
          regularPrice: "￦ 185,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302037828",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302071501",
          image: require("../assets/products/TJ-108.png"),
          title: "태진낚시 돌돔 쌍받침대",
          regularPrice: "￦ 326,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302071501",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/5302256105",
          image: require("../assets/products/TJ-109.png"),
          title: "태진낚시 해상콘도선상용 받침대",
          regularPrice: "￦ 35,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/5302256105",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752539091",
          image: require("../assets/products/TJ-511.png"),
          title: "태진낚시 낚시대 거치대 대포",
          regularPrice: "￦ 30,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752539091",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752542535",
          image: require("../assets/products/TJ-512.png"),
          title: "태진낚시 텐트 고정대 세트",
          regularPrice: "￦ 65,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752542535",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752535676",
          image: require("../assets/products/TJ-302.png"),
          title: "태진낚시 민물발판좌대 1세대",
          regularPrice: "￦ 360,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752535676",
        },
        {
          href: "https://smartstore.naver.com/taejinshop/products/752527626",
          image: require("../assets/products/TJ-301.png"),
          title: "태진낚시 민물좌대 1세대",
          regularPrice: "￦ 390,000",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          detailPage:
            "https://smartstore.naver.com/taejinshop/products/752527626",
        },
      ],
    };
  },
};
</script>

<style scoped>
.tj-hero {
  margin-top: 2.5rem;
}
.brand-new {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 2.5rem;
  font-size: 1.625rem;
  font-weight: 600;
  border: none;
}
.brand-new__paragraph {
  margin-bottom: 0.5rem;
  text-align: center;
}
.brand-new > .tj-button__text {
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 1279px) {
  .tj-call-to-action {
    margin-top: 1rem;
  }
  .brand-new {
    margin-top: 1rem;
    justify-content: space-between;
    font-weight: 700;
    border-bottom: solid 1px #f1f2f3;
    font-size: 1.125rem;
  }
  .brand-new > .tj-button__text {
    position: inherit;
    font-size: 0.75rem;
  }
  .brand-new__paragraph {
    text-align: left;
  }
}
</style>
