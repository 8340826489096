<template>
  <div class="tj-carousel">
    <!-- 슬라이드 -->
    <vue-glide
      v-model="glideIndex"
      :type="glideConfig.type"
      :startAt="glideConfig.startAt"
      :rewind="glideConfig.rewind"
      :autoplay="glideConfig.autoplay"
      :perView="glideConfig.perView"
      :gap="glideConfig.gap"
    >
      <vue-glide-slide v-for="(item, index) in items" :key="index">
        <div class="tj-carousel-item">
          <slot :item="item" />
        </div>
      </vue-glide-slide>

      <!-- 이동 -->
      <template slot="control">
        <div v-if="numberOfPages > 1" class="tj-carousel__control--left" data-glide-dir="<"><i class="fas fa-chevron-left" /></div>
        <div v-if="numberOfPages > 1" class="tj-carousel__control--right" data-glide-dir=">"><i class="fas fa-chevron-right" /></div>
      </template>
    </vue-glide>
  </div>
</template>

<script>
import { isMobileOnly, isTablet } from "mobile-device-detect";

export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      glideIndex: 0,
      glideConfig: {
        type: "carousel",
        rewind: true,
        perView: isMobileOnly ? 2 : isTablet ? 2 : 4,
        gap: 0,
      },
    };
  },
  computed: {
    numberOfPages() {
      return this.items.length;
    },
    page() {
      if (this.glideIndex) {
        return this.glideIndex + 1;
      }
      return 1;
    },
  },
};
</script>

<style scoped>
.tj-carousel {
  position: relative;
}
.tj-carousel-item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  color: #1d1f22;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 0.6875rem;
}
.tj-carousel__control--left,
.tj-carousel__control--right {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  padding: 0 1rem;
  color: #5ece7b;
}
.tj-carousel__control--right {
  right: 0;
}
</style>