<template>
  <div class="tj-image--wrapper">
    <img v-bind="$attrs" v-on="$listeners" :src="src" :alt="alt" :width="width" :height="height" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default: "",
    },
    height: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
};
</script>

<style scoped>
.tj-image--wrapper {
  position: relative;
  display: inline-block;
}
.tj-image--wrapper > img {
  width: inherit;
}
.tj-image {
  object-fit: cover;
  width: auto;
  height: auto;
  display: flex;
}
</style>