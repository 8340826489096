import { render, staticRenderFns } from "./TjButton.vue?vue&type=template&id=5fcc8064&scoped=true&"
import script from "./TjButton.vue?vue&type=script&lang=js&"
export * from "./TjButton.vue?vue&type=script&lang=js&"
import style0 from "./TjButton.vue?vue&type=style&index=0&id=5fcc8064&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fcc8064",
  null
  
)

export default component.exports