import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import Detail from "../views/Detail.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: (route) => ({
      modelName: route.query.modelName
    })
  },
  {
    path: "/detail",
    name: "Detail",
    component: Detail,
    props: (route) => ({
      modelName: route.query.modelName
    })
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else {
      return { x: 0, y: 0 }
    }
  }
})

export default router