import "babel-polyfill"
import Vue from "vue"
import VueGtag from "vue-gtag"
import App from "./App.vue"
import router from "./router"
import VueGlide from "vue-glide-js"
import "vue-glide-js/dist/vue-glide.css"

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {
    id: "UA-71900684-1"
  }
});

Vue.use(VueGlide)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
