<template>
  <div>
    <div class="content-flex-row">
      <div class="aside">
        <div class="aside-image-wrapper">
          <img
            class="aside-image"
            :src="product.image"
            alt="대표이미지"
            :width="product.imageWidth"
            :height="product.imageHeight"
          />
        </div>
      </div>
      <div class="article">
        <div class="section-wrapper">
          <div class="section">
            <div class="product-title">{{ product.title }}</div>
            <div class="product-info-wrapper">
              <div class="product-badge-wrapper">
                <div class="tj-badge">{{ product.badgeLabel }}</div>
              </div>
              <div class="product-price-wrapper">
                <span v-if="!product.specialPrice" class="tj-price__regular">{{
                  product.regularPrice
                }}</span>
                <del v-if="product.specialPrice" class="tj-price__old">{{
                  product.regularPrice
                }}</del>
                <ins v-if="product.specialPrice" class="tj-price__special">{{
                  product.specialPrice
                }}</ins>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="delivery-period">
              <div class="delivery-period-aside">
                <div class="delivery-period-icon">
                  <i class="fas fa-truck"></i>
                </div>
              </div>
              <div class="delivery-period-article">
                <div class="delivery-period-section">
                  <div v-if="product.deliveryPeriodType == 'a'">
                    <strong>주문확인 후 제작 상품</strong>
                    <div class="delivery-period-text-1">
                      <span
                        >이 상품은 주문확인 후 제작을 시작하는 상품으로<br />결제완료
                        후
                      </span>
                      <em class="delivery-period-text-2">7일 이내 발송예정</em>
                      <span class="delivery-period-text-3"> (영업일 기준)</span>
                    </div>
                  </div>
                  <div v-if="product.deliveryPeriodType == 'b'">
                    <strong>해외직배송 상품</strong>
                    <div class="delivery-period-text-1">
                      <span
                        >이 상품은 해외에서 국내로 배송되는 상품으로<br />
                        배송기간은
                      </span>
                      <em class="delivery-period-text-2"
                        >주문일로부터 평균 5~10일정도 소요</em
                      >
                      <span class="delivery-period-text-3"> (영업일 기준)</span>
                    </div>
                  </div>
                  <div v-if="product.deliveryPeriodType == 'c'">
                    <strong>오늘출발 마감(오후 2시까지 결제)</strong>
                    <div class="delivery-period-text-1">
                      <span>배송기간은 </span>
                      <em class="delivery-period-text-2"
                        >주문일로부터 평균 1~3일정도 소요</em
                      >
                      <span class="delivery-period-text-3"> (영업일 기준)</span>
                    </div>
                  </div>
                  <div v-if="product.deliveryPeriodType == 'd'">
                    <strong>주문확인 후 제작 상품</strong>
                    <div class="delivery-period-text-1">
                      <span
                        >이 상품은 주문확인 후 제작을 시작하는 상품으로<br />결제완료
                        후
                      </span>
                      <em class="delivery-period-text-2">15일 이내 발송예정</em>
                      <span class="delivery-period-text-3"> (영업일 기준)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="delivery-method">
              <span>택배배송</span>
              <span class="delivery-method-text-1">
                <span class="delivery-method-text-2">{{
                  product.deliveryPrice
                }}</span
                >원
                <span class="delivery-method-text-3">(주문시 결제)</span>
              </span>
            </div>
            <p class="delivery-method-etc">{{ product.deliveryPriceEtc }}</p>
          </div>
          <div class="section">
            <div class="tj-product-card__buttons">
              <div class="flex-row">
                <TjButton v-if="product.cellPhone" :href="product.cellPhone"
                  ><i class="fas fa-phone"></i><span>전화주문</span></TjButton
                >
                <TjButton
                  class="tj-button__outline"
                  v-if="product.naverTalk"
                  :href="product.naverTalk"
                >
                  <i class="fas fa-comment-alt"></i><span>톡톡문의</span>
                </TjButton>
              </div>
              <div class="flex-row">
                <TjButton
                  class="tj-button__outline"
                  v-if="product.youtube"
                  :href="product.youtube"
                >
                  <i class="fab fa-youtube"></i><span>동영상보기</span>
                </TjButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-flex-column">
      <div class="section">
        <div class="section-title">상품정보</div>
        <div class="table-row-wrapper">
          <div class="table-row">
            <div class="th">제조사</div>
            <div class="td">{{ product.nameOfManufacture }}</div>
          </div>
          <div class="table-row">
            <div class="th">브랜드</div>
            <div class="td">{{ product.brand }}</div>
          </div>
        </div>
        <div class="table-row-wrapper">
          <div class="table-row">
            <div class="th">모델명</div>
            <div class="td">{{ modelNameString }}</div>
          </div>
          <div class="table-row">
            <div class="th">원산지</div>
            <div class="td">{{ product.origin }}</div>
          </div>
        </div>
        <div class="table-row-wrapper">
          <div class="table-row">
            <div class="th">제조일자</div>
            <div class="td">{{ product.dateOfManufacture }}</div>
          </div>
          <div class="table-row">
            <div class="th">영수증발급</div>
            <div class="td">신용카드전표, 현금영수증발급</div>
          </div>
        </div>
        <div class="table-row-wrapper">
          <div class="table-row as">
            <div class="th as">A/S 안내</div>
            <div class="td as">
              <span>055-327-7267</span><br />
              <span v-html="product.afterServiceInfo"></span>
            </div>
          </div>
          <div class="table-row as dummy">
            <div class="th as"></div>
            <div class="td as"></div>
          </div>
        </div>
      </div>
      <div class="section viewer">
        <iframe
          class="youtube-iframe"
          v-if="product.youtube"
          id="youtubeIframe"
          width="100%"
          :height="youtubeHeight"
          :src="product.youtube"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <img
          v-for="(image, index) in product.images"
          :key="index"
          :src="image"
        />
      </div>
      <div class="section">
        <iframe
          src="https://ads-partners.coupang.com/widgets.html?id=482603&template=carousel&trackingCode=AF9555338&subId=&width=1240&height=100"
          width="100%"
          height="100"
          frameborder="0"
          scrolling="no"
          referrerpolicy="unsafe-url"
        ></iframe>
      </div>
      <div class="section">
        <div class="section-title">상품정보 제공고시</div>
        <div class="table-row-wrapper offer-notice">
          <div class="table-row offer-notice multi-line">
            <div class="th multi-line">품명 / 모델명</div>
            <div class="td multi-line">상품 상세설명 참조</div>
          </div>
          <div class="table-row offer-notice">
            <div class="th">크기</div>
            <div class="td">상품 상세설명 참조</div>
          </div>
        </div>
        <div class="table-row-wrapper offer-notice">
          <div class="table-row offer-notice">
            <div class="th">중량</div>
            <div class="td">상품 상세설명 참조</div>
          </div>
          <div class="table-row offer-notice multi-line">
            <div class="th multi-line">색상</div>
            <div class="td multi-line">
              상품 상세설명 참조 (모니터 화질에 따라 다소 차이가 있을 수 있음)
            </div>
          </div>
        </div>
        <div class="table-row-wrapper offer-notice">
          <div class="table-row offer-notice">
            <div class="th">재질</div>
            <div class="td">상품 상세설명 참조</div>
          </div>
          <div class="table-row offer-notice">
            <div class="th">제품구성</div>
            <div class="td">상품 상세설명 참조</div>
          </div>
        </div>
        <div class="table-row-wrapper offer-notice">
          <div class="table-row offer-notice multi-line">
            <div class="th multi-line">동일모델의 출시연월</div>
            <div class="td multi-line">{{ product.dateOfManufacture }}</div>
          </div>
          <div class="table-row offer-notice">
            <div class="th">제조자(사)</div>
            <div class="td">{{ product.nameOfManufacture }}</div>
          </div>
        </div>
        <div class="table-row-wrapper offer-notice">
          <div class="table-row offer-notice">
            <div class="th">제조국</div>
            <div class="td">{{ product.origin }}</div>
          </div>
          <div class="table-row offer-notice">
            <div class="th">상품별 세부사양</div>
            <div class="td">상품 상세설명 참조</div>
          </div>
        </div>
        <div class="table-row-wrapper offer-notice">
          <div class="table-row offer-notice multi-line">
            <div class="th multi-line">품질보증기준</div>
            <div class="td multi-line">
              수령 후 사용전 7일이내 교환, 반품가능 / 낚시 특성상 사용 후 파손은
              유상 A/S
            </div>
          </div>
          <div class="table-row offer-notice multi-line">
            <div class="th multi-line">AS 책임자와 전화번호</div>
            <div class="td multi-line">상품 상세설명 참조</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TjButton from "../components/atoms/TjButton.vue";

export default {
  components: {
    TjButton: TjButton,
  },
  props: {
    modelName: {
      type: String,
      default: "",
    },
  },
  methods: {
    getYoutubeSrc(id) {
      if (!id) {
        return "";
      }
      return "https://www.youtube.com/embed/" + id;
    },
  },
  mounted() {
    const youtubeIframe = document.getElementById("youtubeIframe");
    if (youtubeIframe) {
      this.youtubeHeight = (youtubeIframe.clientWidth / 16) * 9 + "px";
    }
  },
  data() {
    return {
      products: {
        SH100088: {
          badgeLabel: "신상품 12.6% 할인",
          image: require("../assets/products/SH100088/000.png"),
          title: "이지코리아 BR-311 리필전지 50개",
          regularPrice: "￦ 40,000",
          specialPrice: "￦ 34,960",
          cellPhone: "tel:0553277267",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          deliveryPeriodType: "c",
          deliveryPrice: "3,500",
          deliveryPriceEtc: "제주 추가 3,000원, 제주 외 도서지역 추가 5,000원",
          nameOfManufacture: "이지코리아",
          brand: "이지코리아(자체제작 상품)",
          origin: "국산(경기도 동두천시)",
          dateOfManufacture: "제품 뒷면 하단에 표기",
          afterServiceInfo:
            "상담가능시간: 평일 오전 10시 ~ 오후 6시<br>일, 공휴일은 상담이 불가합니다.",
          imageHeight: "100%",
          images: [
            require("../assets/products/SH100088/000.png"),
            require("../assets/products/SH100088/001.png"),
            require("../assets/products/SH100088/002.png"),
            require("../assets/products/SH100088/003.png"),
            require("../assets/products/SH100088/004.png"),
            require("../assets/products/SH100088/005.png"),
          ],
        },
        SH100100: {
          badgeLabel: "신상품 12.6% 할인",
          image: require("../assets/products/SH100100/000.png"),
          title: "이지코리아 이지 광케미 리필램프(3mm)",
          regularPrice: "￦ 5,000",
          specialPrice: "￦ 4,370",
          cellPhone: "tel:0553277267",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          deliveryPeriodType: "c",
          deliveryPrice: "3,500",
          deliveryPriceEtc: "제주 추가 3,000원, 제주 외 도서지역 추가 5,000원",
          nameOfManufacture: "이지코리아",
          brand: "이지코리아(자체제작 상품)",
          origin: "국산(경기도 동두천시)",
          dateOfManufacture: "제품 뒷면 하단에 표기",
          afterServiceInfo:
            "상담가능시간: 평일 오전 10시 ~ 오후 6시<br>일, 공휴일은 상담이 불가합니다.",
          imageHeight: "100%",
          images: [
            require("../assets/products/SH100100/000.png"),
            require("../assets/products/SH100100/001.png"),
            require("../assets/products/SH100100/002.png"),
            require("../assets/products/SH100100/003.png"),
            require("../assets/products/SH100100/004.png"),
            require("../assets/products/SH100100/005.png"),
            require("../assets/products/SH100100/006.png"),
          ],
        },
        TJ_306: {
          badgeLabel: "신상품",
          image: require("../assets/products/TJ_306/000.png"),
          title: "태진낚시 무동력 가스 온수보일러",
          regularPrice: "￦ 280,000",
          cellPhone: "tel:0553277267",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          deliveryPeriodType: "a",
          deliveryPrice: "6,500",
          deliveryPriceEtc:
            "제주 추가 12,100원, 제주 외 도서지역 추가 12,100원",
          nameOfManufacture: "태진기계",
          brand: "태진낚시(자체제작 상품)",
          origin: "국산(경상남도 김해시)",
          dateOfManufacture: "2020. 12",
          afterServiceInfo:
            "상담가능시간: 평일 오전 10시 ~ 오후 6시<br>일, 공휴일은 상담이 불가합니다.",
          youtube: this.getYoutubeSrc("fZ5zoWfOuMk"),
          imageHeight: "100%",
          images: [
            require("../assets/products/TJ_306/000.png"),
            require("../assets/products/TJ_306/001.png"),
            require("../assets/products/TJ_306/002.png"),
            require("../assets/products/TJ_306/003.png"),
            require("../assets/products/TJ_306/004.png"),
            require("../assets/products/TJ_306/005.png"),
            require("../assets/products/TJ_306/006.png"),
            require("../assets/products/TJ_306/007.png"),
            require("../assets/products/TJ_306/008.png"),
            require("../assets/products/TJ_306/009.png"),
            require("../assets/products/TJ_306/010.png"),
            require("../assets/products/TJ_306/011.png"),
            require("../assets/products/TJ_306/012.png"),
            require("../assets/products/TJ_306/013.png"),
            require("../assets/products/TJ_306/014.png"),
            require("../assets/products/TJ_306/015.png"),
            require("../assets/products/TJ_306/016.png"),
            require("../assets/products/TJ_306/017.png"),
            require("../assets/products/TJ_306/018.png"),
            require("../assets/products/TJ_306/019.png"),
            require("../assets/products/TJ_306/020.png"),
            require("../assets/products/TJ_306/021.png"),
            require("../assets/products/TJ_306/022.png"),
            require("../assets/products/TJ_306/023.png"),
            require("../assets/products/TJ_306/024.png"),
            require("../assets/products/TJ_306/025.png"),
            require("../assets/products/TJ_306/026.png"),
            require("../assets/products/TJ_306/027.png"),
            require("../assets/products/TJ_306/028.png"),
            require("../assets/products/TJ_306/029.png"),
            require("../assets/products/TJ_306/030.png"),
            require("../assets/products/TJ_306/031.png"),
            require("../assets/products/TJ_306/032.png"),
            require("../assets/products/TJ_306/033.png"),
            require("../assets/products/TJ_306/034.png"),
          ],
        },
        TJ_601: {
          badgeLabel: "신상품",
          image: require("../assets/products/TJ_601/000.jpg"),
          title: "TJ-601 태진낚시 태진보트",
          regularPrice: "￦ 3,300,000",
          cellPhone: "tel:0553277267",
          naverTalk: "https://talk.naver.com/WC4WUQ",
          deliveryPeriodType: "d",
          deliveryPrice: "0",
          nameOfManufacture: "태진기계",
          brand: "태진낚시(자체제작 상품)",
          origin: "국산(경상남도 김해시)",
          dateOfManufacture: "2022. 12",
          afterServiceInfo:
            "상담가능시간: 평일 오전 10시 ~ 오후 6시<br>일, 공휴일은 상담이 불가합니다.",
          imageHeight: "100%",
          images: [
            require("../assets/products/TJ_601/000.jpg"),
            require("../assets/products/TJ_601/000-1.png"),
            require("../assets/products/TJ_601/001.jpg"),
            require("../assets/products/TJ_601/002.jpg"),
            require("../assets/products/TJ_601/003.jpg"),
            require("../assets/products/TJ_601/004.jpg"),
            require("../assets/products/TJ_601/005.jpg"),
            require("../assets/products/TJ_601/005-1.png"),
            require("../assets/products/TJ_601/006.jpg"),
            require("../assets/products/TJ_601/007.jpg"),
            require("../assets/products/TJ_601/008.jpg"),
            require("../assets/products/TJ_601/009.jpg"),
            require("../assets/products/TJ_601/009-1.png"),
            require("../assets/products/TJ_601/010.jpg"),
            require("../assets/products/TJ_601/010-1.png"),
            require("../assets/products/TJ_601/011.jpg"),
            require("../assets/products/TJ_601/011-1.png"),
            require("../assets/products/TJ_601/012.jpg"),
            require("../assets/products/TJ_601/012-1.png"),
            require("../assets/products/TJ_601/013.jpg"),
            require("../assets/products/TJ_601/013-1.png"),
            require("../assets/products/TJ_601/014-1.png"),
            require("../assets/products/TJ_601/015-1.png"),
            require("../assets/products/TJ_601/016-1.png"),
          ],
        },
      },
      youtubeHeight: this.youtubeHeight,
    };
  },
  computed: {
    product() {
      return this.products[this.modelName];
    },
    modelNameString() {
      return this.modelName.replace("_", "-");
    },
  },
};
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
}
.content-flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  margin-top: 2.5rem;
  position: relative;
  border: 1px solid #ededed;
  background-color: #ffffff;
}
.aside {
  width: 50%;
  margin: -1px 0 0 -1px;
  box-sizing: border-box;
}
.aside-image-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  height: 510px;
}
.aside-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  vertical-align: middle;
}
.article {
  width: 50%;
  padding: 1.875rem 2.5rem;
  box-sizing: border-box;
}
.section-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.section {
  width: 100%;
  margin-bottom: 1rem;
}
.product-title {
  font-size: 1.25rem;
}
.product-info-wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 15px;
}
.product-badge-wrapper {
  width: 30%;
}
.tj-badge {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: #5ece7b;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.6;
}
.product-price-wrapper {
  width: 70%;
  text-align: right;
}
.tj-price {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
}
.tj-price__regular {
  margin: 0;
  color: #1d1f22;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6;
  text-decoration: none;
}
.tj-price__special {
  margin: 0;
  color: #1d1f22;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6;
  text-decoration: none;
}
.tj-price__old {
  margin: 0 0.5rem 0 0;
  color: #d12727;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.6;
  text-decoration: line-through;
}
.delivery-period {
  display: table;
  width: 100%;
  padding: 0.875rem 1rem;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  table-layout: fixed;
}
.delivery-period-aside {
  display: table-cell;
  position: relative;
  width: 3.25rem;
  vertical-align: middle;
}
.delivery-period-icon {
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #5ece7b;
  text-align: center;
}
.delivery-period-icon i {
  vertical-align: middle;
  color: #ffffff;
  line-height: 2.5rem;
  font-size: 1rem;
}
.delivery-period-article {
  display: table-cell;
  line-height: 1.6;
  vertical-align: middle;
}
.delivery-period-section {
  font-size: 0.75rem;
}
.delivery-period-text-1 {
  color: #72757e;
}
.delivery-period-text-2 {
  color: #5ece7b;
  font-weight: bold;
}
.delivery-period-text-3 {
  color: #8d8f9a;
}
.delivery-method {
  padding-left: 1px;
  color: #1d1f22;
  font-size: 0.875rem;
  line-height: 1.6;
}
.delivery-method-text-1 {
  position: relative;
}
.delivery-method-text-1::before {
  display: inline-block;
  vertical-align: top;
  margin: 4px 9px 0 7px;
  width: 1px;
  height: 0.875rem;
  background-color: #8d8f9a;
  content: "";
}
.delivery-method-text-2 {
  font-weight: bold;
}
.delivery-method-text-3 {
  color: #5ece7b;
  padding-left: 1px;
}
.delivery-method-etc {
  padding-left: 1px;
  margin-top: 3px;
  color: #8d8f9a;
  word-break: break-all;
  font-size: 0.875rem;
}
.tj-product-card__buttons {
  display: flex;
  flex-direction: column;
}
.tj-product-card__buttons .tj-button {
  width: 50%;
  font-size: 0.875rem;
}
.tj-product-card__buttons i {
  padding-right: 0.3125rem;
}
.content-flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  position: relative;
  border: 1px solid #ededed;
  background-color: #ffffff;
}
.section-title {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 1.25rem;
}
.table-row-wrapper {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ededed;
}
.table-row-wrapper:last-child {
  border-bottom: 1px solid #ededed;
}
.table-row {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 2.5rem;
}
.table-row .th {
  width: 40%;
  height: 2.5rem;
  line-height: 2.5rem;
  background-color: #fafafa;
  font-weight: 700;
  color: #1d1f22;
  text-align: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.25rem;
  border-right: 1px solid #ededed;
}
.table-row .td {
  width: 60%;
  height: 2.5rem;
  line-height: 2.5rem;
  color: #72757e;
  word-break: break-all;
  padding-left: 1.25rem;
}
.table-row.as {
  height: 4.8rem;
  line-height: 4.8rem;
}
.table-row .th.as {
  height: 4.8rem;
  line-height: 4.8rem;
}
.table-row .td.as {
  height: 4.8rem;
  line-height: 1.6rem;
}
.youtube-iframe {
  max-width: 860px;
}
.viewer {
  text-align: center;
}
.viewer img {
  width: 100%;
  max-width: 860px;
}
.table-row-wrapper.offer-notice {
  flex-direction: column;
  border: none;
}
.table-row-wrapper:last-child.offer-notice {
  border-bottom: 1px solid #ededed;
}
.table-row.offer-notice {
  width: 100%;
  border-top: 1px solid #ededed;
}
.table-row.offer-notice .th {
  width: 30%;
}
.table-row.offer-notice .td {
  width: 70%;
}
.table-row.dummy.offer-notice {
  display: none;
}
@media (max-width: 1023px) {
  .table-row .td.as {
    height: 4.8rem;
    line-height: 1.6rem;
    font-size: 0.75rem;
  }
}
@media (max-width: 863px) {
  .content-flex-row {
    flex-direction: column;
  }
  .aside {
    width: 100%;
    margin: 0;
  }
  .aside-image-wrapper {
    height: 255px;
  }
  .article {
    width: 100%;
  }
  .table-row.offer-notice {
    height: 1.6rem;
  }
  .table-row.offer-notice .th {
    height: 1.6rem;
    font-size: 0.75rem;
    line-height: 1.6rem;
  }
  .table-row.offer-notice .td {
    height: 1.6rem;
    font-size: 0.75rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 779px) {
  .section-title {
    padding-left: 0.625rem;
  }
  .table-row-wrapper {
    flex-direction: column;
  }
  .table-row {
    width: 100%;
  }
  .table-row.dummy {
    display: none;
  }
  .table-row .th {
    width: 35%;
    padding-left: 0.625rem;
  }
  .table-row .td {
    width: 65%;
    padding-left: 0.625rem;
  }
}
@media (max-width: 619px) {
  .table-row.offer-notice .th {
    font-size: 0.5rem;
  }
  .table-row.offer-notice .td {
    font-size: 0.5rem;
  }
}
@media (max-width: 529px) {
  .article {
    padding: 1rem;
  }
  .table-row.offer-notice.multi-line {
    height: 2.8rem;
    line-height: 1.6rem;
    vertical-align: middle;
  }
  .table-row.offer-notice .th.multi-line {
    height: 2.8rem;
    line-height: 1.6rem;
    vertical-align: middle;
  }
  .table-row.offer-notice .td.multi-line {
    height: 2.8rem;
    line-height: 1.6rem;
    vertical-align: middle;
  }
}
@media (max-width: 399px) {
  .section-title {
    font-size: 1rem;
  }
  .table-row-wrapper {
    border: none;
  }
  .table-row-wrapper:last-child {
    border: none;
  }
  .table-row {
    height: 1.6rem;
    border-top: 1px solid #ededed;
  }
  .table-row .th {
    height: 1.6rem;
    font-size: 0.75rem;
    line-height: 1.6rem;
  }
  .table-row .td {
    height: 1.6rem;
    font-size: 0.75rem;
    line-height: 1.6rem;
  }
  .table-row.as {
    border-bottom: 1px solid #ededed;
    height: 3.6rem;
    line-height: 3.6rem;
  }
  .table-row .th.as {
    height: 3.6rem;
    line-height: 3.6rem;
  }
  .table-row .td.as {
    height: 3.6rem;
    line-height: 1.125rem;
    font-size: 0.5rem;
  }
}
</style>
