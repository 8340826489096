<template>
  <header class="tj-header">
    <div class="tj-header__wrapper">
      <div>
        <div name="logo">
          <router-link to="/">
            <img class="tj-header__logo" :src="logo" :alt="title" />
          </router-link>
        </div>
        <div class="tj-header__aside"></div>
        <div class="tj-header__actions">
          <nav class="tj-header__navigation">
            <div class="tj-header__navigation-item" v-for="(category, key) in categories" v-bind:key="key">
              <div class="tj-header__navigation-item__item">
                <div name="navigation-item">
                  <TjLink class="tj-header-navigation-item__link" :to="category.to" :href="category.href" target="category.href ? _blank : _self">{{
                    category.label
                  }}</TjLink>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import TjLink from "../components/atoms/TjLink.vue";

export default {
  components: {
    TjLink: TjLink,
  },
  data() {
    return {
      logo: require("../assets/banner-taejinfish.png"),
      title: "태진낚시",
      categories: [
        {
          label: "구매하러 가기",
          href: "https://smartstore.naver.com/taejinshop",
          to: "",
        },
        {
          label: "제품영상 보러가기",
          href: "https://www.youtube.com/channel/UCl85zz4lYwkH9xfO2bWBl0w?sub_confirmation=1",
          to: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
.tj-header {
  position: relative;
}
.tj-header__wrapper {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: #ffffff;
  transition: transform 150ms ease-in-out;
  box-shadow: none;
}
.tj-header__wrapper > div {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  max-width: 77.5rem;
  margin: 0 auto;
}
.tj-header__logo {
  width: auto;
  height: 5.625rem;
  flex: 0 0 auto;
  margin: 0.5rem 0;
}
.tj-header__aside {
  margin: 0 0 0 auto;
}
.tj-header__actions {
  flex: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
}
.tj-header__navigation {
  order: 0;
  display: flex;
  flex: 0 0 auto;
  margin: 0 auto 0 2.5rem;
}
.tj-header__navigation-item {
  position: static;
  display: flex;
  flex: 1;
}
.tj-header__navigation-item__item {
  white-space: nowrap;
  box-sizing: border-box;
  color: #43464e;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  margin: 0;
  border: 0;
  border-bottom: 2px;
  border-style: solid;
  border-color: transparent;
  transition: border-color 150ms ease-in-out, color 150ms ease-in-out;
  text-decoration: initial;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
}
.tj-header__navigation-item__item:hover {
  color: #5ece7b;
  border-color: #5ece7b;
}
.tj-header-navigation-item__link {
  text-decoration: none;
}
@media (max-width: 599px) {
  .tj-header__navigation {
    margin: 0 auto 0 1rem;
    flex-direction: column;
  }
  .tj-header__navigation-item__item {
    padding: 0.5rem 0;
  }
}
</style>