<template>
  <div class="tj-product-card">
    <div class="tj-product-card__image-wrapper">
      <TjButton class="tj-product-card__link" :href="item.href" :to="item.to">
        <TjImage class="tj-product-card__image" :src="item.image" :alt="item.title" :width="item.imageWidth" :height="item.imageHeight" />
      </TjButton>
      <div class="tj-product-card__badge tj-badge" v-if="item.badgeLabel">{{ item.badgeLabel }}</div>
    </div>
    <!-- title -->
    <h3 class="tj-product-card__title">{{ item.title }}</h3>
    <!-- price -->
    <div class="tj-price">
      <span v-if="!item.specialPrice" class="tj-price__regular">{{ item.regularPrice }}</span>
      <del v-if="item.specialPrice" class="tj-price__old">{{ item.regularPrice }}</del>
      <ins v-if="item.specialPrice" class="tj-price__special">{{ item.specialPrice }}</ins>
    </div>
    <div class="tj-product-card__buttons">
      <TjButton class="tj-button__outline" v-if="item.youtube" :href="item.youtube"><i class="fab fa-youtube"></i><span>동영상보기</span></TjButton>
      <TjButton v-if="item.cellPhone" :href="item.cellPhone"><i class="fas fa-phone"></i><span>전화주문</span></TjButton>
      <TjButton class="tj-button__outline" v-if="item.naverTalk" :href="item.naverTalk"><i class="fas fa-comment-alt"></i><span>톡톡문의</span></TjButton>
      <TjButton v-if="!item.to && item.detailPage" :href="item.detailPage"><i class="fas fa-shopping-cart"></i><span>상세보기</span></TjButton>
      <TjButton v-if="item.to" :to="item.to"><i class="fas fa-shopping-cart"></i><span>상세보기</span></TjButton>
    </div>
  </div>
</template>

<script>
import TjButton from "../components/atoms/TjButton.vue";
import TjImage from "../components/atoms/TjImage.vue";

export default {
  components: {
    TjButton: TjButton,
    TjImage: TjImage,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style scoped>
.tj-button {
  padding: 0;
}
.tj-product-card {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  max-width: 15.5rem;
  flex: 0 1 15.5rem;
  padding: 1rem;
  background-color: #ffffff;
}
.tj-product-card::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: box-shadow 150ms ease-in-out;
}
.tj-product-card:hover {
  z-index: 1;
  box-shadow: 0px 4px 11px rgba(29, 31, 34, 0.1);
}
.tj-product-card:active {
  box-shadow: 0px 4px 11px rgba(29, 31, 34, 0.1);
}
.tj-product-card__image-wrapper {
  position: relative;
  width: 100%;
}
.tj-product-card__link {
  display: block;
  width: 100%;
  line-height: 0;
  text-decoration: none;
  margin: 0;
  height: auto;
  transition: none;
  box-shadow: none;
  background: none;
}
.tj-product-card__image {
  width: inherit;
}
.tj-product-card__link:hover {
  background: none;
  box-shadow: none;
}
.tj-product-card__badge {
  position: absolute;
  top: 0.5rem;
  left: 0;
}
.tj-badge {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: #5ece7b;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.6;
}
.tj-badge__full-width {
  width: 100%;
}
.tj-product-card__title {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  color: #43464e;
  margin: 0.5rem 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  min-height: 3.125rem;
}
.tj-price {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
}
.tj-price__regular {
  margin: 0;
  color: #1d1f22;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6;
  text-decoration: none;
}
.tj-price__special {
  margin: 0;
  color: #1d1f22;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6;
  text-decoration: none;
}
.tj-price__old {
  margin: 0 0.5rem 0 0;
  color: #d12727;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.6;
  text-decoration: line-through;
}
.tj-product-card__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
}
.tj-product-card__buttons .tj-button {
  width: 50%;
  font-size: 0.875rem;
}
.tj-product-card__buttons i {
  padding-right: 0.3125rem;
}
@media (max-width: 414px) {
  .tj-product-card {
    max-width: 10.625rem;
    flex: 10.625rem;
    padding: 0.5rem;
  }
}
@media (max-width: 899px) {
  .tj-product-card__buttons i {
    display: none;
  }
}
</style>