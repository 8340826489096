<template>
  <component v-bind="attrs" :is="is" :to="to" :href="href" :target="target">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      default: "",
    },
    href: {
      type: [String, Object],
      default: "",
    },
  },
  computed: {
    attrs() {
      const attrs = {};

      if (this.href) {
        attrs.href = this.href;
      }

      return attrs;
    },
    is() {
      if (this.href) {
        return "a";
      }

      if (this.to) {
        return "router-link";
      }

      return "button";
    },
    target() {
      if (this.href && !this.disabled) {
        return "_blank";
      }

      return "_self";
    },
  },
};
</script>