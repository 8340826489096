<template>
  <component class="tj-button" :class="{ 'is-disabled--button': disabled }" v-bind="attrs" :is="is" :to="to" :href="href" :target="target" :disabled="disabled">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: "",
    },
    href: {
      type: [String, Object],
      default: "",
    },
  },
  computed: {
    attrs() {
      const attrs = {};

      if (this.href) {
        attrs.href = this.href;
      }

      return attrs;
    },
    is() {
      if (this.href) {
        return "a";
      }

      if (this.to) {
        return "router-link";
      }

      return "button";
    },
    target() {
      if (this.href && !this.disabled) {
        return "_blank";
      }

      return "_self";
    },
  },
};
</script>

<style scoped>
.tj-button {
  box-sizing: border-box;
  position: relative;
  width: 10rem;
  height: 3.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem; /* 1rem 1.5rem */
  color: #ffffff;
  background: #5ece7b;
  transition: background 0.8s linear, box-shadow 0.8s linear;
  background-position: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
  white-space: nowrap;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2;
  border: 0 solid #5ece7b;
  box-shadow: 4px 12px 24px 0px rgba(114, 117, 126, 0);
}
.tj-button:hover {
  box-shadow: 4px 12px 24px 0px rgba(114, 117, 126, 0.25);
  background: #5ece7b radial-gradient(circle, transparent 1%, #9ee2b0 1%) center/15000%;
}
.tj-button.is-disabled--button,
.tj-button.is-disabled--button:hover {
  color: #e0e0e1;
  border-color: transparent;
  background: #f1f2f3;
  box-shadow: none;
  pointer-events: none;
  cursor: default;
}
.tj-button__text {
  border-width: 0;
  padding: 0;
  background: transparent;
  color: #1d1f22;
  text-transform: none;
  text-decoration: underline;
  font-size: 0.875rem;
  font-weight: 500;
  box-shadow: none;
}
.tj-button__text:hover {
  background: transparent;
  box-shadow: none;
  color: #5ece7b;
}
.tj-button__text:active {
  color: #72757e;
}
.tj-button__outline {
  background: transparent;
  border: 1px solid #5ece7b;
  color: #5ece7b;
}
.tj-button__outline:hover {
  background: transparent;
}
</style>