<template>
  <div>
    <div class="tj-footer__wrapper tj-footer__wrapper_1023">
      <div class="tj-footer__wrapper__mobile">
        <div class="tj-footer__content">
          <div class="title">고객센터</div>
          <div class="tel">055-327-7267</div>
          <div>
            <span class="label" style="background-color: #8d8f9a">평 일</span> 오전 10:00 ~ 오후 5:00<br />
            <span class="label"></span> (점심시간 12시 ~ 1시)
          </div>
          <div><span class="label" style="background-color: #8d8f9a">토요일</span> 오전 10:00 ~ 오후 1:00</div>
          <div><span class="label" style="background-color: #d12727">휴무일</span> 일요일/공휴일</div>
        </div>
        <div class="tj-footer__divide"></div>
        <div class="tj-footer__content">
          <div class="title">계좌번호</div>
          <TjImage style="height: 32px" :src="require('../assets/busan-bank.jpg')" alt="배너-태진낚시"></TjImage>
          <div class="account-number">035-12-066194-9</div>
          <div style="color: #72757e">예금주 : 정태영</div>
        </div>
      </div>
      <div class="tj-footer__wrapper__mobile">
        <div class="tj-footer__divide tj-show-719"></div>
        <div class="tj-footer__wrapper__tablet">
          <div class="tj-footer__divide tj-hide-1279"></div>
          <div class="tj-footer__content">
            <div class="title">커뮤니티</div>
            <TjButton class="tj-button__outline" href="https://band.us/n/a4a655E9B8K1u">
              <i class="fas fa-ring"></i>
              <span>네이버 밴드<br />바로가기</span>
            </TjButton>
            <TjButton class="tj-button__outline" href="https://talk.naver.com/WC4WUQ">
              <i class="fas fa-comment-alt"></i>
              <span>네이버 톡톡<br />문의하기</span>
            </TjButton>
          </div>
          <div class="tj-footer__divide"></div>
          <div class="tj-footer__content">
            <div class="title">바로가기</div>
            <div class="tj-footer__wrapper">
              <TjButton class="tj-button__outline" href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=6063992142&apv_perm_no=">
                <i class="fas fa-info"></i>
                <span>공정거래위원회<br />사업자정보확인</span>
              </TjButton>
              <TjButton class="tj-button__outline" href="https://smartstore.naver.com/taejinshop">
                <i class="fas fa-store"></i>
                <span>네이버<br />스마트스토어</span>
              </TjButton>
              <TjButton class="tj-button__outline" href="https://blog.naver.com/taejinshop">
                <i class="fas fa-blog"></i>
                <span>네이버 블로그<br />바로가기</span>
              </TjButton>
            </div>
            <div class="tj-footer__wrapper">
              <TjButton class="tj-button__outline" href="https://www.youtube.com/channel/UCl85zz4lYwkH9xfO2bWBl0w?sub_confirmation=1">
                <i class="fab fa-youtube"></i>
                <span>유튜브</span>
              </TjButton>
              <TjButton class="tj-button__outline" href="https://www.facebook.com/taejinfish">
                <i class="fab fa-facebook-square"></i>
                <span>페이스북</span>
              </TjButton>
              <TjButton class="tj-button__outline" href="https://www.instagram.com/taejinfish">
                <i class="fab fa-instagram-square"></i>
                <span>인스타그램</span>
              </TjButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tj-footer__wrapper" style="margin-top: 1rem">
      <TjImage class="tj-hide-399" :src="require('../assets/banner-taejinfish.png')" alt="배너-태진낚시"></TjImage>
      <div class="tj-footer__divide tj-hide-399"></div>
      <div class="tj-footer__content" style="justify-content: center">
        <div>상호 : 태진낚시 / 대표자 : 정태영</div>
        <div>사업자등록번호 : 606-39-92142 / 통신판매신고 : 2012-경남김해-0257</div>
        <div>고객센터 : 055-327-7267 / 팩스 : 055-327-7269 / 주소 : 경상남도 김해시 동북로473번길 210 (우) 50804</div>
        <div>개인정보책임자 : 정태영 / 이메일 : taejinshop@naver.com</div>
      </div>
    </div>
  </div>
</template>

<script>
import TjButton from "../components/atoms/TjButton.vue";
import TjImage from "../components/atoms/TjImage.vue";

export default {
  components: {
    TjButton: TjButton,
    TjImage: TjImage,
  },
};
</script>

<style>
.tj-footer__wrapper {
  display: flex;
  flex-direction: row;
}
.tj-footer__wrapper i {
  padding-right: 0.625rem;
  font-size: 1.5rem;
}
.tj-footer__wrapper .tj-button__outline {
  color: #72757e;
  border-color: #72757e;
  margin: 0 0.25rem 0.25rem 0;
}
.tj-footer__wrapper__tablet {
  display: inherit;
}
.tj-footer__wrapper__mobile {
  display: inherit;
}
.tj-footer__divide {
  border: 1px dashed #8d8f9a;
  margin: 1rem;
}
.tj-footer__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.title {
  font-weight: 600;
  font-size: 1.25rem;
}
.tel {
  font-weight: 700;
  font-size: 1.625rem;
  color: #5ece7b;
}
.label {
  font-size: 0.875rem;
  color: #ffffff;
  width: 3.5rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.275rem;
}
.account-number {
  font-weight: 700;
  font-size: 1.25rem;
  color: #1d1f22;
  min-width: 13.75rem;
}
.tj-show-719 {
  display: none;
}
.tj-hide-399 {
  display: block;
}
@media (max-width: 1279px) {
  .tj-footer__wrapper__tablet {
    flex-direction: column;
  }
  .tj-hide-1279 {
    display: none;
  }
}
@media (max-width: 1023px) {
  .tj-footer__wrapper_1023 {
    flex-direction: column;
  }
  .tj-footer__wrapper__tablet {
    margin-top: 1rem;
    flex-direction: row;
    width: 100%;
  }
  .tj-footer__wrapper__mobile {
    flex-direction: row;
  }
}
@media (max-width: 719px) {
  .tj-footer__wrapper {
    flex-direction: column;
  }
  .tj-footer__wrapper__tablet {
    margin-top: 0;
  }
  .tj-footer__wrapper__mobile {
    flex-direction: column;
  }
  .tj-show-719 {
    display: block;
  }
}
@media (max-width: 399px) {
  .tj-footer__wrapper i {
    padding-right: 0.3125rem;
    font-size: 1.5rem;
  }
  .tj-footer__wrapper .tj-button__outline {
    font-size: 0.875rem;
    width: 7.5rem;
  }
  .tj-hide-399 {
    display: none !important;
  }
}
</style>