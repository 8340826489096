<template>
  <div id="app">
    <Header></Header>
    <router-view id="router-view" />
    <Footer id="footer"></Footer>
  </div>
</template>

<script>
import Header from "./views/Header.vue";
import Footer from "./views/Footer.vue";

export default {
  components: {
    Header: Header,
    Footer: Footer,
  },
};
</script>

<style>
* {
  font-family: "Noto Sans KR", sans-serif;
  color: inherit;
}
body {
  background-color: #ffffff;
  margin: 0;
}
ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
#router-view {
  max-width: 77.5rem;
  margin: 0 auto;
  padding: 0 1rem;
  padding-top: 5.625rem;
}
#footer {
  max-width: 77.5rem;
  margin: 0 auto;
  padding: 1rem;
  margin-top: 2.5rem;
}
em {
  font-style: normal;
}
</style>
